::ng-deep nb-tabset {
  display: flex !important;
  gap: 1rem;
  flex-direction: row;

  .tabset {
    display: flex;
    flex-direction: column !important;
    gap: 0.25rem;
    padding-right: 1rem !important;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: var(--color-basic-300);
    font-size: 14px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .tab-link {
    padding: 0.75rem 1rem !important;

    &::before {
      display: none !important;
    }
  }

  .tab {
    border-radius: 0.25rem;

    &.active {
      color: var(--color-info-500);
      background-color: var(--color-info-100);

      .tab-link {
        color: var(--color-info-500);
      }
    }

    &:hover {
      .tab-link {
        color: var(--color-info-500);
      }
    }
  }
}

@media (max-width: 768px) {
  ::ng-deep {
    nb-tabset {
      flex-direction: column !important;
      gap: 0 !important;

      .tabset {
        flex-direction: row !important;
        flex-wrap: wrap !important;
        border-width: 0 0 1px 0 !important;
      }
    }
  }
}
