@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
}

@import "styles/fonts";
@import "styles/list-page.scss";
@import "styles/detail-page.scss";
@import "styles/nebular";

html, body, input, textarea, button {
  font-variant-numeric: lining-nums;
}

.noselect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label {
  text-transform: uppercase;
}
