.filter-control-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.list-container {
  .list-filter-card {
    .list-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      .list-filter-title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 24px;
        gap: 0.5rem;

        nb-icon {
          font-size: 1.5rem;
        }
      }

      .list-filter-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0 1rem;

        nb-select {
          width: 14rem;
        }

        input {
          width: 14rem;
        }
      }
    }
  }


  .list-data-card {
    height: 60vh;

    .row-header {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
    }

    nb-card-header {
      padding-left: 1rem;
    }

    .btn-item {
      font-size: 24px;
      color: nb-theme(color-primary-500);
    }

    .address-item {
      font-size: 16px;
      width: 20rem;
    }

    .middle-item {
      font-size: 16px;
      width: 10rem;
    }

    .large-item {
      font-size: 16px;
      width: 25rem;
    }

    .text-item {
      width: 24rem;
    }

    .small-item {
      font-size: 16px;
      width: 6rem;
    }

    .list-card-body {
      padding: 0;
      overflow-y: scroll;
      min-width: 60rem;

      nb-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0.25rem 0.25rem 0.25rem 1rem;
        gap: 1rem;
        cursor: pointer;

        &:nth-child(even) {
          background-color: nb-theme(color-basic-200);
        }

        &:hover {
          background-color: nb-theme(color-basic-300);
        }

        .data-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 2rem;
          width: 100%;

          .address-card {
            width: 20rem;
          }
        }
      }
    }
  }

  .pagination-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
