.detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  nb-card {
    width: 100%;
    min-width: 30vw;
    min-height: 30vh;
    max-height: 100vh;
    overflow-y: auto;

    nb-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      h5 {
        flex-grow: 1;

        nb-icon {
          cursor: pointer;
          font-size: 1.5rem;
          color: nb-theme(color-primary-500);
        }
      }
    }

    nb-card-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  .error {
    padding: 1px 1px 0;
    margin-bottom: 0;
    font-size: 12px;
    color: red;
  }
}

.flex-grow {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .detail-container {
    nb-card {
      nb-card-body {
        padding: 0.25rem;
      }
    }
  }
}
