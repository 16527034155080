@forward '../node_modules/@nebular/theme/styles/theming';
@use '../node_modules/@nebular/theme/styles/theming' as *;
@use '../node_modules/@nebular/theme/styles/themes/default';
@use "styles/tabset-override";

$nb-themes: nb-register-theme((), default, default);

$primary-color: #BE1823;

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

$nb-themes: nb-register-theme((
  /* Font */
  font-family-primary: 'Raleway',
  font-family-secondary: 'Raleway',
  /* Colors */
  color-primary-100: white,
  color-primary-200: mix($primary-color, white, 40%),
  color-primary-300: mix($primary-color, white, 75%),
  color-primary-400: mix($primary-color, white, 90%),
  color-primary-500: $primary-color,
  color-primary-600: mix(black, $primary-color, 40%),
  color-primary-700: mix(black, $primary-color, 75%),
  color-primary-800: mix(black, $primary-color, 90%),
  color-primary-900: black,
  color-primary-1000: black,
  color-primary-1100: black,
  text-basic-color: color-basic-700,
  text-disabled-color: color-basic-600,
  select-outline-basic-placeholder-text-color: color-basic-600,
  select-outline-primary-disabled-text-color: color-basic-600,
  /* Other */
  list-item-padding: 0,
  tabset-content-padding: 1rem 0.5rem,
  accordion-shadow: none,
  card-margin-bottom: 0,
  tabset-tab-text-transform: none,
  table-row: color-basic-200,
  hover: color-basic-300,
  layout-min-height: calc(100vh - 1px) // Fix unnecessary scrollbar
), wr, default);
