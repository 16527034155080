@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  src: url("/assets/fonts/raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: url("/assets/fonts/raleway/Raleway-SemiBold.ttf") format("truetype");
}
